import React, { useState, useEffect } from 'react';
import axiosInstance from '../axios';
import { PtcHeaderWrap, PtcInfoWrap, InfoContainer, InfoWrap, TitleInfoWrap } from '../components/PtcView/PtcViewElements';
import { DescWrap } from '../components/History/DepositTabElements';
import { StackContainer, StackWrap, StackDiscWrap, TitleWrap } from '../components/Stack/StackElements';
import { Table, HeadWrap, HeadRow, Header, BodyWrap, BodyRow, Cell } from '../components/TaskView/TaskPageElements';
import { TabContainer, TabHeaderContainer, TabHeaderWrap, TabWrap, ChallengeWrap, ChallengeName, FaucetIcon, ViewAdsIcon, OfferIcon, AffiliateIcon, ProgressBarWrap, ProgressBar, MembershipContainer, MembershipWrap, ImageWrap, Image, Button, Timer } from '../components/LuckyWheel/LuckyWheelElements';
import { dailyBonusTabHeader, spinTableHead, spinMembership } from '../data/dailyBonusData';
import LuckyWheel from '../components/LuckyWheel';
import Countdown, { zeroPad } from 'react-countdown';
import { Circle } from '../components/Loading';
import { MsgWrap, MsgText } from '../components/MessageElements';
import moment from 'moment';

const DailyBonus = () => {
    const [info, setInfo] = useState();
    const [loading, setLoading] = useState(true);
    const [apiError, setApiError] = useState('');
    const [active, setActive] = useState('');
    const [tabActive, setTabActive] = useState('normal');
    const [claimable, setClaimable] = useState();
    const [claimed, setClaimed] = useState(false);
    const [error, setError] = useState('');
    let add_spin = 0;
    const renderer = ({ hours, minutes, seconds }) => {
	return <Timer style={{ margin: '15px auto' }} red={info.timer < 60 * 60 ? 1 : 0}>{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</Timer>;
    };
    useEffect(() => {
	const fetchDailyBonusInfo = async () => {
	    try {
	        const { data } = await axiosInstance.get('earning/spin/info/');
	        setInfo(data);
	        data.membership === 1.0 ? setActive('alpha') : data.membership === 0.75 ? setActive('beta') : setActive('omega');
	        tabActive === 'normal' && data.faucet >= 5 && data.ptc >= 15 && data.shortlink >= 3 && data.offer >= 50 && setClaimable('normal');
	        setClaimed(data.claimed);
	    } catch (error) {
	        error.response && error.response.data.detail ? setApiError(error.response.data.detail) : setApiError(error.message);
	    };
	    setLoading(false);
        };
	info === undefined && !error && loading && fetchDailyBonusInfo();
    });
    const handleTabClick = (tab) => {
	if (tab !== tabActive) {
	    tab === 'normal' && info.faucet >= 3 && info.ptc >= 15 && info.shortlink >= 3 && info.offer >= 50 && setClaimable(tab);
	    tab === 'shortlinker' && info.shortlink >= 25 && setClaimable(tab);
	    tab === 'offerist' && info.offer >= 2500 && setClaimable(tab);
	    tab === 'digital marketer' && info.referral >= 1 && setClaimable(tab);
	    setTabActive(tab);
	    setError('');
	};
    };
    const handleClick = () => {
        if (claimable === tabActive && info.spin <= 21) {
	    setActive('');
	    info.membership === 1.0 ? add_spin = 5 : info.membership === 0.75 ? add_spin = 3 : add_spin = 1;
	    setInfo({ ...info, spin: info.spin + add_spin });
	    fetchDailyBonusClaim();
	} else if (info.spin >= 21) {
	    setError('Your spin balance is full, please spin the lucky wheel first.');
	} else {
	    tabActive === 'normal' && setError('You must claim 3 faucets, view 15 PTC, surf or video ads, complete 3 Shortlinks, and earn 50 PC from the offer, after you provide these requirements, click on the button.');
	    tabActive === 'shortlinker' && setError('You must complete 25 Shortlinks after you provide these requirements, click on the button.');
	    tabActive === 'offerist' && setError('You must earn 2500 PC from the offer, after you provide this requirement, click on the button.');
	    tabActive === 'digital marketer' && setError('You must invite 1 friend by your referral link, after you provide this requirement, click on the button.');
	};
    };
    const fetchDailyBonusClaim = async () => {
	try {
	    await axiosInstance.post('earning/daily-bonus/claim/', { tab: tabActive });
	} catch (error) {
	    error.response && error.response.data.detail ? setApiError(error.response.data.detail) : setApiError(error.message);
	};
    };
    return (
	<>
	    {loading &&
		<div style={{ width: '100%', height: '90vh', display: 'flex', alignItems: 'center' }}>
		    <Circle style={{ margin: 'auto', width: '100px', height: '100px' }} />
		</div>
	    }
	    {!loading && apiError &&
		<MsgWrap primary={0} style={{ width: '80%', margin: '15px auto 25px auto' }}>
		    <MsgText primary={0}>
			{apiError}
		    </MsgText>
		</MsgWrap>
	    }
	    {!loading && !apiError &&
		<PtcHeaderWrap>
		    <PtcInfoWrap>
			<InfoContainer>
			    <InfoWrap>
			        <TitleInfoWrap>
			            <h2>today</h2>
			        </TitleInfoWrap>
			        <DescWrap>
			            <h2>{info.all_info.number_today_spin}</h2>
			        </DescWrap>
			    </InfoWrap>
			    <InfoWrap>
			        <TitleInfoWrap>
			            <h2>this week</h2>
			        </TitleInfoWrap>
			        <DescWrap>
			            <h2>{info.all_info.number_this_week_spin}</h2>
			        </DescWrap>
			    </InfoWrap>
			</InfoContainer>
		    </PtcInfoWrap>
		</PtcHeaderWrap>
	    }
	    {!loading && !apiError && info !== undefined &&
		<StackContainer primary={1} style={{ top: '0' }}>
		    <StackWrap primary={1}>
			<TitleWrap primary={1}>
			    <h2>guidance</h2>
			</TitleWrap>
		        <StackDiscWrap>
			    <p>
			        Select your favorite tab and complete its requirements to get your daily bonus. <br />
			        Boost your number of daily spins by upgrading your account. <br />
			        Spin the lucky wheel whenever you like. <br />
			        The maximum limit for spin balance is 21.
			    </p>
		        </StackDiscWrap>
		    </StackWrap>
	        </StackContainer>
	    }
	    {!loading && !apiError && info !== undefined && !claimed &&
		<PtcHeaderWrap>
		    <PtcInfoWrap style={{ background: 'rgba(39, 117, 85, 0.7)', borderColor: 'rgba(39, 117, 85, 0.6)', boxShadow: '0 0 20px rgba(39, 117, 85, 0.7)' }}>
			<Countdown
		            date={Date.now() + info.timer * 1000}
		            renderer={renderer}
		        />
			<TabContainer>
			    <TabHeaderContainer>
			        {dailyBonusTabHeader.map((item, index) => (
				    <TabHeaderWrap key={index} active={item === tabActive ? 1 : 0} onClick={() => handleTabClick(item)}>
					<h2>{item}</h2>
				    </TabHeaderWrap>
				))}
		            </TabHeaderContainer>
			    <TabWrap>
			        {tabActive === 'normal' &&
				    <>
					<ChallengeWrap>
			    		    <ChallengeName>
			        		<FaucetIcon />
			        		<h2>
			            		    Faucet:
		                		</h2>
			    		    </ChallengeName>
			    		    <ProgressBarWrap>
			        		<ProgressBar width={info.faucet > 0 && info.faucet <= 3 ? (String(info.faucet / 3 * 100) + '%') : info.faucet > 3 ? '100%' : 0}>
			            		    <span>{info.faucet >= 3 ? 'ok' : (String(info.faucet) + '/3')}</span>
			        	        </ProgressBar>
			    		    </ProgressBarWrap>
					</ChallengeWrap>
					<ChallengeWrap>
			    		    <ChallengeName>
			        		<ViewAdsIcon />
			        		<h2>
			            		    View Ads:
		                		</h2>
			    		    </ChallengeName>
			    		    <ProgressBarWrap>
			        		<ProgressBar width={info.ptc > 0 && info.ptc <= 15 ? (String(info.ptc / 15 * 100) + '%') : info.ptc > 15 ? '100%' : 0}>
			            		    <span>{info.ptc >= 15 ? 'ok' : (String(info.ptc) + '/15')}</span>
			        		</ProgressBar>
			    		    </ProgressBarWrap>
					</ChallengeWrap>
					<ChallengeWrap>
			    		    <ChallengeName>
			        		<ViewAdsIcon />
			        		<h2>
			            		    Shortlink:
		                		</h2>
			    		    </ChallengeName>
			    		    <ProgressBarWrap>
			        		<ProgressBar width={info.shortlink > 0 && info.shortlink <= 3 ? (String(info.shortlink / 3 * 100) + '%') : info.shortlink > 3 ? '100%' : 0}>
			            		    <span>{info.shortlink >= 3 ? 'ok' : (String(info.shortlink) + '/3')}</span>
			        		</ProgressBar>
			    		    </ProgressBarWrap>
					</ChallengeWrap>
					<ChallengeWrap>
			    		    <ChallengeName>
			        		<OfferIcon />
			        		<h2>
			            		    Offer:
		                		</h2>
			    		    </ChallengeName>
			    		    <ProgressBarWrap>
			        		<ProgressBar width={info.offer > 0 && info.offer <= 50 ? (String(info.offer / 50 * 100) + '%') : info.offer > 50 ? '100%' : 0}>
			            		    <span>{info.offer >= 50 ? 'ok' : (String(info.offer) + '/50 PC')}</span>
			        		</ProgressBar>
			    		    </ProgressBarWrap>
					</ChallengeWrap>
				    </>
				}
		    		{tabActive === 'shortlinker' &&
				    <>
					<ChallengeWrap>
					    <ChallengeName>
						<ViewAdsIcon />
						<h2>
						    Shortlink:
					        </h2>
					    </ChallengeName>
					    <ProgressBarWrap>
						<ProgressBar width={info.shortlink > 0 && info.shortlink <= 25 ? (String(info.shortlink / 25 * 100) + '%') : info.shortlink > 25 ? '100%' : 0}>
						    <span>{info.shortlink >= 25 ? 'ok' : (String(info.shortlink) + '/25')}</span>
						</ProgressBar>
					    </ProgressBarWrap>
					</ChallengeWrap>
				    </>
				}
		    		{tabActive === 'offerist' &&
				    <>
					<ChallengeWrap>
					    <ChallengeName>
						<OfferIcon />
						<h2>
						    Offer:
					        </h2>
					    </ChallengeName>
					    <ProgressBarWrap>
						<ProgressBar width={info.offer > 0 && info.offer <= 2500 ? (String(info.offer / 2500 * 100) + '%') : info.offer > 2500 ? '100%' : 0}>
						    <span>{info.offer >= 2500 ? 'ok' : (String(info.offer) + '/2500 PC')}</span>
						</ProgressBar>
					    </ProgressBarWrap>
					</ChallengeWrap>
				    </>
				}
		    		{tabActive === 'digital marketer' &&
				    <>
					<ChallengeWrap>
					    <ChallengeName>
						<AffiliateIcon />
						<h2>
						    refferal:
					        </h2>
					    </ChallengeName>
					    <ProgressBarWrap>
						<ProgressBar width={info.referral > 0 && info.referral <= 1 ? (String(info.referral / 1 * 100) + '%') : info.referral > 1 ? '100%' : 0}>
						    <span>{info.referral >= 1 ? 'ok' : (String(info.referral) + '/1')}</span>
						</ProgressBar>
					    </ProgressBarWrap>
					</ChallengeWrap>
				    </>
				}
		            </TabWrap>
			</TabContainer>
			<MembershipContainer>
			    {spinMembership.map((item, index) => (
				<MembershipWrap key={index} active={active === item.alt ? 1 : 0}>
				    <ImageWrap>
					<Image src={item.src} alt={item.alt} />
				    </ImageWrap>
				    <Button type='button' onClick={active ? handleClick : null}>
					{item.button}
				    </Button>
				</MembershipWrap>
			    ))}
			</MembershipContainer>
		        {error &&
			    <MsgWrap primary={0} style={{ width: '80%' }}>
				<MsgText primary={0}>
				    {error}
				</MsgText>
			    </MsgWrap>
			}
		    </PtcInfoWrap>
		</PtcHeaderWrap>
	    }
	    {!loading && !apiError && info !== undefined &&
		<StackContainer primary={1} style={{ top: '0' }}>
		    <StackWrap primary={1}>
			<TitleWrap primary={1}>
			    <h2>Lucky Wheel</h2>
			</TitleWrap>
			<LuckyWheel info={info} />
			{!loading && !apiError && info.info.length > 0 &&
			    <Table style={{ marginTop: '25px' }}>
				<HeadWrap>
				    <HeadRow>
					{spinTableHead.map((header, index) => (
					    <Header key={index}>
						{header}
					    </Header>
					))}
				    </HeadRow>
				</HeadWrap>
				<BodyWrap>
				    {info.info.map((row, index) => (
					<BodyRow key={index}>
					    <Cell>
						{row.id}
					    </Cell>
					    <Cell>
						{row.username ? row.username : row.user}
					    </Cell>
					    <Cell>
						{row.reward}
					    </Cell>
					    <Cell>
						{moment(row.created).fromNow()}
					    </Cell>
					</BodyRow>
				    ))}
				</BodyWrap>
			    </Table>
			}
		    </StackWrap>
		</StackContainer>
	    }
	</>
    )
}
export default DailyBonus;
